import React from 'react'
import NotificationContainer from '../components/NotificationContainer'

const NotificationPage = () => {
    

  return (
    <NotificationContainer/>
  )
}

export default NotificationPage