import React from 'react'
import Services from './Services'

const OtherServiceContent = () => {
  return (
    <div className='other_service_content'>
        <Services/>
    </div>
  )
}

export default OtherServiceContent