import React from 'react'

const ServicesHeaderImg = () => {
  return (
    <div className='services_header_img container'>

    </div>
  )
}

export default ServicesHeaderImg