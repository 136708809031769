import React from 'react'
import TeachersContainer from './TeachersContainer'

const Teachers = () => {
  return (
    <div className='news'>
        <TeachersContainer/>
    </div>
  )
}

export default Teachers


