import React from 'react'
import AboutContainer from './AboutContainer'

const About = () => {
  return (
    <div className='about'>
        <AboutContainer/>
    </div>
  )
}

export default About