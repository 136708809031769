import React from 'react'
import Publication from './Publication'

const OtherPublicationContent = () => {
  return (
    <div className='other_service_content'>
        <Publication/>
    </div>
  )
}

export default OtherPublicationContent