import React from 'react'
import FooterSecondContainer from './FooterSecondContainer'

const FooterSecond = () => {
  return (
    <div className='footer_second'>
        <FooterSecondContainer/>
    </div>
  )
}

export default FooterSecond